module.exports = {
  siteTitle: "Era", // <title>
  description: "Creamos Arquitectura de habitos",
  manifestName: "Era",
  manifestShortName: "Era", // max 12 characters
  manifestStartUrl: "/",
  manifestBackgroundColor: "#A76F3E",
  manifestThemeColor: "#A76F3E",
  manifestDisplay: "standalone",
  manifestIcon: "src/assets/img/ERA2.png",
  pathPrefix: `/highlights/`, // This path is subpath of your hosting https://domain/portfolio
  heading: "Era",
  subHeading: "Creamos Arquitectura de hábitos",

  menuSections: [
    {
      name: "HOME",
      url: "/Home"
    },
    {
      name: "NOSOTROS",
      url: "/Nosotros"
    },
    {
      name: "PROYECTOS",
      url: "/Proyectos",
      categories:  [
        
         {name:"Arquitectura"},
         {name:"Interiorismo"},
        ]
    },
    {
      name: "CONTACTO",
      url: "/Home#contactFooter"
    }
  ],
  // social
  socialLinks: [

    {
      icon: "fa-instagram",
      name: "instagram",
      url: "https://instagram.com/arquitecturaera"
    },
    {
      icon: "fa-whatsapp",
      name: "Whatsapp",
      url: "https://api.whatsapp.com/send?phone=573108220342&text=Quiero_información_de_Era"
    },
  ],

  contact: [
    //Eduardo
    {tel: "3108220342", mail: "emunera@erarquitectura.com.co"},
    //Mariana
    {tel: "3108220342", mail: "administracion@erarquitectura.com.co"},
    {tel: "3108220342", mail: "administracion@erarquitectura.com.co"}
  ],
//"NOS INSPIRA LA DIVERSIDAD, NOS ACEPTAMOS, SOMOS AMABLES, RESPETAMOS LA ESENCIA DE CADA UNNO, AMAMOS DISEÑAR, NOS INSPIRA LA FORMA DE VIVIR DE LAS PERSONAS, SU MOMENTO, SUS ETAPAS, AMAMOS SUS HÁBITOS PORQUE LOS HACEN ÚNICOS Y CREEMOS QUE SON EL PUNTO DE PARTIDA PARA CREAR UNA BUENA ARQUITECTURA."
  nosotros: [
    {
      name: "MANIFIESTO",
      subTitle: "",
      description: "Nos inspira la diversidad, nos aceptamos, somos amables, respetamos la esencia de cada uno, amamos diseñar, nos inspira la forma de vivir de las personas, su momento, sus etapas, amamos sus hábitos porque los hacen únicos y creemos que son el punto de partida para crear una buena arquitectura.",
      team : false
    },
    { name: "ARQUITECTO LÍDER", subTitle: "Eduardo Múnera", description: " Arquitecto de la Universidad Pontificia bolivariana, trabajé durante ocho años en la firma de diseño Juan Forero arquitectos como arquitecto líder en varios proyectos residenciales e institucionales. Fundador del estudio de arquitectura y diseño ERA arquitectura, especializados en el desarrollo de proyectos de residenciales de alto nivel en diferentes escalas."
   , team : true },
    {
      name: "PROPÓSITO",
      subTitle: "¿ Cuál es nuestra lucha ?",
      description:
        "Crear la arquitectura correcta, que sea funcional, sostenible ambientalmente y que responda a su contexto.",
        team : false
    }
  ],


  proyectos: [
    
    {category:"Arquitectura", description: ""},
    {category:"Interiorismo", description: ""},
  ]
  /*

{
  name:'',
  query:``
},

*/
};
